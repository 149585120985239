/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: 'e2312f8875cf8bdd300191b572a35827652e1674' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('') {
  const key = 'undefined' ? '@undefined' : '';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: 'e2312f8875cf8bdd300191b572a35827652e1674' };
}

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://fbf829c90c444882a363693041917b99@o379608.ingest.sentry.io/4504312391270400',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
